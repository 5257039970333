// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import SubpageHeroTeaser from '../../../components/SubpageHeroTeaser'
import Contacts from './../Contacts'

import messages from './PrivacyPageApplicants.lang'
import baseStyles from './PrivacyPageApplicants.scss'

/* eslint-disable max-len */
const imageDesktop = (
  <StaticImage
    src="../../../images/heroteaser/imprint_heroteaser_bg_desktop.jpg"
    alt=""
    objectFit="cover"
    className="image"
  />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const PrivacyPageApplicants = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser
        title={formatMessage(messages.pageTitle)}
        image={imageDesktop}
        styles={styles.teaser}
        imgAlt="office gallery"
      />
      <Container fluid>
        <Contacts />
        <div className={styles.text}>
          <FormattedMessageSanitized tagName="div" {...messages.pageText} />
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(PrivacyPageApplicants)
